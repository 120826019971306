<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">public hearings</div>
      </v-row>
      <v-row class="">
        <div class="">
          A public hearing is a meeting, or portion of a meeting, that enables
          the public to speak upon a specific subject matter. Public hearings
          are conducted when required by law or when directed by the County
          Assembly. Generally, public hearings are conducted by the committee
          having proper jurisdiction over the subject matter
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-5">
      <v-card-title>
        <v-menu
          v-model="fromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="from"
              label="From Date"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="from"
            @input="fromMenu = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="to"
              label="To Date"
              outlined
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
        </v-menu>

        <v-autocomplete
          prepend-icon="mdi-account"
          label="Commitee"
          :items="commiteeItems"
          outlined
        ></v-autocomplete>
      </v-card-title>

      <v-card-title>
        <v-icon>mdi-text-search</v-icon>
        <div class="text-uppercase">Search Results</div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          label="Search"
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>

      <v-card color="transparent">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              class="text-capitalize elevation-10"
              no-data-text="No results for the current date"
              @click:row="pdfDialog = true"
            >
            </v-data-table>

            <v-dialog v-model="pdfDialog">
              <pdf-viewer />
            </v-dialog>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      toMenu: false,
      fromMenu: false,
      pdfDialog: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      commiteeItems: [],
      headers: [
        {
          text: "date",
          value: "introDate",
          class: "text-uppercase black--text",
        },
        {
          text: "commitee name",
          value: "commitee",
          class: "text-uppercase black--text",
        },
        {
          text: "public hearing",
          value: "description",
          width: "50%",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          ordinanceNo: "2021-044",
          passDate: "OCT8, 2021",
          author: "Jenkins",
          file: "2021-38949",
          title: "Budget",
          enactDate: "Dec 10, 2021",
          introDate: "Dec 10, 2021",
          commitee: "POGO",
          subject: "Campaign finance disclosure ordinances",
          description:
            "Receiving and filing public comment on proposed maps of ward boundary and park district changes.",
        },
        {
          ordinanceNo: "2021-044",
          passDate: "OCT8, 2021",
          author: "Jenkins",
          file: "2021-38949",
          title: "Budget",
          enactDate: "Dec 10, 2021",
          introDate: "Dec 10, 2021",
          commitee: "POGO",
          subject: "Campaign finance disclosure ordinances",
          description:
            "Passage of Ordinance amending Title 8.5, Chapter 167 relating to Elections: Municipal Elections: Rules of Conduct, amending statutory citations related to campaign finance disclosure for municipal candidates.",
        },
        {
          ordinanceNo: "2021-044",
          passDate: "OCT8, 2021",
          author: "Jenkins",
          file: "2021-38949",
          title: "Budget",
          enactDate: "Dec 10, 2021",
          introDate: "Dec 10, 2021",
          commitee: "POGO",
          subject: "Campaign finance disclosure ordinances",
          description:
            "Passage of Ordinance amending Title 8.5, Chapter 167 relating to Elections: Municipal Elections: Rules of Conduct, amending statutory citations related to campaign finance disclosure for municipal candidates.",
        },
      ],
    };
  },
};
</script>

<style></style>
